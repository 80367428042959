import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { FORMAT_DATE, QUERY_KEY } from 'constants/constants';
import { IPhotoIntelligenceData, IPhotoIntelligenceDetailData } from 'constants/photoIntelligence';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { getPhotoIntelligenceAssociatedKps, getPhotoIntelligenceKpDetail } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeAssociatedKpInfo,
  changeDetailBarList,
  changeSelectedKpInfo,
  photoIntelligenceSelector,
} from 'store/slices/map-view/photoIntelligenceAnalytics';
import { changeParentAnalyticsId, mapViewSelector } from 'store/slices/mapViewSlice';

interface AssociatedKpsProps {
  data: IPhotoIntelligenceData;
}

const AssociatedKps: FC<AssociatedKpsProps> = ({ data }) => {
  const [kpList, setKpList] = useState<IPhotoIntelligenceDetailData[]>([]);
  const { t } = useTranslation();
  const { _id } = data || {};
  const { levelId, dateAnalyticSelected } = useAppSelector(mapViewSelector);
  const { barDetailList, selectedKp } = useAppSelector(photoIntelligenceSelector);
  const dispatch = useAppDispatch();

  const { isLoading, isRefetching } = useQuery<IPhotoIntelligenceDetailData[]>(
    [QUERY_KEY.PHOTO_INTELLIGENCE_ASSOCIATED_LIST, _id, levelId],
    () =>
      getPhotoIntelligenceAssociatedKps({
        kpId: _id,
        levelId: levelId!,
      }),
    {
      onSuccess(data) {
        const sortData = data.sort((a, b) => {
          const aMoment = moment(a.analysisId.date);
          const bMoment = moment(b.analysisId.date);
          return bMoment.isAfter(aMoment) ? 1 : -1;
        });
        setKpList(sortData);
      },
      enabled: !!_id && !!levelId,
    }
  );

  //Make sure the selected KP is fully expanded before performing a scroll.
  useEffect(() => {
    if (selectedKp._id && kpList.length > 0) {
      const interval = setInterval(() => {
        const element = document.getElementById(`photo_bar_${selectedKp._id}`);
        if (element) {
          const isExpanded = element.offsetHeight > 100;
          if (isExpanded) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
            clearInterval(interval);
          }
        }
      }, 50);

      return () => clearInterval(interval);
    }
  }, [kpList, selectedKp]);

  const getPhotoIntelligenceKpDetailMutation = useMutation(getPhotoIntelligenceKpDetail, {
    onSuccess(data: IPhotoIntelligenceDetailData) {
      const {
        _id: targetKpId,
        lon,
        lat,
        analysisId: { date, parentId, _id: analysisId },
      } = data;
      const formattedDate = moment.utc(date).format(FORMAT_DATE);
      if (dateAnalyticSelected !== formattedDate) {
        dispatch(changeAssociatedKpInfo({ id: targetKpId, date: formattedDate, isDone: false }));
        dispatch(changeParentAnalyticsId({ parentId: parentId, analyticId: analysisId }));
      }

      const newBarDetailList = barDetailList.map((item) =>
        item.id === targetKpId ? { ...item, isOpenCollapse: true } : { ...item, isOpenCollapse: false }
      );
      dispatch(changeDetailBarList(newBarDetailList));
      dispatch(changeSelectedKpInfo({ _id: targetKpId, lon, lat, isDone: false }));

      onScrollToTargetKp(targetKpId);
    },
  });

  const onScrollToTargetKp = (targetId: string) => {
    const element = document.getElementById(`photo_bar_${targetId}`);
    element?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
  };

  const renderSkeleton = [1, 2].map((_, i) => <Skeleton key={i} variant="text" sx={{ fontSize: '1rem' }} />);

  const renderKpList = kpList.map((item) => (
    <Typography key={item._id}>
      <Box
        component="span"
        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => getPhotoIntelligenceKpDetailMutation.mutate({ kpId: item._id })}>
        {item.kpName}
      </Box>
      <Box component="span" sx={{ ml: '4px', opacity: 0.7 }}>
        {`(${moment(item.analysisId?.date).format('DD-MM-YYYY')})`}
      </Box>
    </Typography>
  ));

  const renderEmptyKpList = <Typography>{t('trans.no_associated_kp')}</Typography>;

  return (
    <Box sx={{ mt: '8px' }}>
      {!!kpList?.length && <Typography>{t('trans.associated_kp')}</Typography>}
      <Stack>{isLoading || isRefetching ? renderSkeleton : kpList?.length ? renderKpList : renderEmptyKpList}</Stack>
    </Box>
  );
};

export default AssociatedKps;
