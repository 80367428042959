import { Grid, Typography } from '@mui/material';
import { MousePositionEnum, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { clientLengthUnitMeasurement, clientOtherUnitMeasurement } from 'components/MapView/RightBar/IssuesTab/utils';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Marker } from 'react-map-gl';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { rightBarSelector } from 'store/slices/rightBarSlice';

const fixedSize = {
  height: 250,
  width: 350,
};

interface IDistance {
  key: string;
  value: number;
}

interface StandCountMarkerProps {
  hoverInfo: any;
  mouseRef: any;
  analyticName: TYPE_ANALYTICS_MAP_VIEW;
}

const StandCountMarker: FC<StandCountMarkerProps> = ({ hoverInfo, mouseRef, analyticName }) => {
  const { drawerShow } = useAppSelector(mapViewSelector);
  const { otherSetting, lengthSetting } = useQueryClientSetting();
  const { linesVectorDisplay } = useAppSelector(rightBarSelector);
  const [displayedLine] = linesVectorDisplay.filter((item) => item.visible);
  const { t } = useTranslation();

  const distanceJson = hoverInfo.properties?.distance;
  let distanceList: IDistance[] = [];
  try {
    distanceList = distanceJson ? JSON.parse(distanceJson) : [];
  } catch (err) {
    distanceList = [];
  }

  const lineCategory = displayedLine?.lineCategory;
  const distanceValue = distanceList.find((item) => item?.key === lineCategory)?.value;

  let dataInfoList = [
    { label: t('mapView.tree_id'), value: hoverInfo.properties.treeId },
    { label: t('mapView.longitude'), value: hoverInfo.properties.longX },
    { label: t('mapView.latitude'), value: hoverInfo.properties.latY },
  ];

  if (analyticName === TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT) {
    dataInfoList = [
      ...dataInfoList,
      { label: t('mapView.status'), value: t(convertTextToMultiLanguage(hoverInfo.properties.status, 'mapView')) },
    ];
  } else if (analyticName === TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT) {
    dataInfoList = [
      ...dataInfoList,
      {
        label: t('mapView.tree_height'),
        value: `${clientLengthUnitMeasurement(hoverInfo.properties.height, lengthSetting)} ${lengthSetting.unit}`,
      },
      ...(lineCategory
        ? [
            {
              label: `${t('mapView.distance_to')} ${lineCategory || ''}`,
              value: `${distanceValue || 0} ${lengthSetting.unit}`,
            },
          ]
        : []),
    ];
  } else if (analyticName === TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION) {
    dataInfoList = [
      ...dataInfoList,
      {
        label: t('mapView.tree_height'),
        value: `${clientLengthUnitMeasurement(hoverInfo.properties.height, lengthSetting)} ${lengthSetting.unit}`,
      },
      {
        label: t('mapView.wood_density'),
        value: `${clientOtherUnitMeasurement(hoverInfo.properties?.woodDensity, otherSetting)} g/cm³`,
      },
      {
        label: t('mapView.basal_area'),
        value: `${clientOtherUnitMeasurement(hoverInfo.properties?.basalArea, otherSetting)} m²/Ha`,
      },
      {
        label: t('mapView.aboveground_carbon_density'),
        value: `${clientOtherUnitMeasurement(hoverInfo.properties?.abovegroundCarbonDensity, otherSetting)} MgC/Ha`,
      },
      {
        label: t('mapView.co2_sequestration'),
        value: `${clientOtherUnitMeasurement(hoverInfo.properties?.co2Sequestration, otherSetting)} Mg/Ha`,
      },
    ];
  }

  const detectMousePosition = () => {
    // 64px is width of menu bar
    let clientX = mouseRef.current.clientX - 64;
    let clientY = mouseRef.current.clientY;

    let innerWidth = window.innerWidth - 64;
    const innerHeight = window.innerHeight;

    // 300px is width of left sideBar
    if (drawerShow.leftBar) {
      clientX -= 300;
      innerWidth -= 300;
    }

    // 454px is width of right sideBar
    if (drawerShow.rightBar) {
      innerWidth -= 454;
    }

    if (clientY < innerHeight / 2) {
      if (clientX < fixedSize.width / 2) return MousePositionEnum.TOP_LEFT;
      else if (innerWidth - clientX < fixedSize.width / 2) return MousePositionEnum.TOP_RIGHT;
      else return MousePositionEnum.TOP_CENTER;
    } else {
      if (clientX < fixedSize.width / 2) return MousePositionEnum.BOTTOM_LEFT;
      else if (innerWidth - clientX < fixedSize.width / 2) return MousePositionEnum.BOTTOM_RIGHT;
      else return MousePositionEnum.BOTTOM_CENTER;
    }
  };

  const calculateOffsetYWithPosition = () => {
    if (detectMousePosition().includes('top')) return 18;
    else return -20;
  };

  return (
    <Marker
      longitude={hoverInfo.properties.longX}
      latitude={hoverInfo.properties.latY}
      anchor={detectMousePosition()}
      style={{ zIndex: 10 }}
      offset={[0, calculateOffsetYWithPosition()]}>
      <Grid
        maxWidth={400}
        minWidth={400}
        height={'fit-content'}
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          border: 0,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          borderRadius: '5px',
          display: 'flex',
          paddingX: '4px',
          alignItems: 'center',
          pointerEvents: 'none',
          transformOrigin: '0 0 0',
          position: 'relative',
          zIndex: 10,
        }}>
        <Grid container padding={1} gap={0.25}>
          {dataInfoList.map((item, index) => (
            <Grid
              key={index}
              item
              xs={12}
              direction="row"
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                component="span"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
                }}>
                {item.label}
              </Typography>
              <Typography
                sx={{
                  textAlign: 'right',
                  fontSize: '14px',
                  fontWeight: 'normal',
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5',
                  padding: '2px',
                  color: '#1C9855',
                  width: '60%',
                  wordWrap: 'break-word',
                }}>
                {item.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Marker>
  );
};

export default StandCountMarker;
