import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import { Grid, GridProps, IconButton, styled, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { IRasterCardItemProps, RoleType } from 'common/defines/clients';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { convertAnalyticNameToMultiLanguage, convertTextToMultiLanguage } from 'common/utils/convert';
import { ClientActionsDialog } from 'components/ClientsInfo/ClientActionsDialog';
import { QUERY_KEY } from 'constants/constants';
import { TreeItemComponentProps } from 'dnd-kit-sortable-tree';
import { forwardRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteRasterAnalytic, getExportAnalyticScript } from 'services/analytics/apiAnalyticsConfig.services';
import { queryMe } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  anaLyticConfigSelector,
  changePageRasterPagination,
  changeSensorSelected,
} from 'store/slices/analyticsConfigSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { RasterCard } from '../RasterCard';

const Card = styled((props: { isSelected: boolean; isCompulsoryAnalysis: boolean } & GridProps) => <Grid {...props} />)(
  ({ theme, isSelected, isCompulsoryAnalysis }) => ({
    border: `1px solid ${theme.palette.color.grey2}`,
    borderRadius: theme.spacing(0.5),
    width: `calc(100% - ${theme.spacing(16)})`,
    height: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    marginLeft: theme.spacing(16),
    cursor: 'pointer',
    backgroundColor: isSelected
      ? theme.palette.color.green5
      : isCompulsoryAnalysis
        ? theme.palette.color.yellow300
        : '',
    '&:hover': {
      backgroundColor: theme.palette.color.green5,
    },
  })
);

const ButtonCustom = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(4),
  minWidth: 'initial',
  height: theme.spacing(4),
  marginLeft: theme.spacing(1),
}));

const RasterItemEnable = [
  TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT,
  TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS,
  TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT,
  TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION,
  TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA,
  TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE,
  TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE,
  TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE,
  TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE,
];

const RasterCardItem = forwardRef<HTMLDivElement, TreeItemComponentProps<IRasterCardItemProps>>((props, ref) => {
  const {
    item,
    wrapperRef,
    indentationWidth,
    depth,
    clone,
    ghost,
    disableSelection,
    disableInteraction,
    style,
    manualDrag,
    handleProps,
    ...rest
  } = props;
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const { t } = useTranslation();
  const { levelId } = useAppSelector(mapViewSelector);
  const { pageRaster } = useAppSelector(anaLyticConfigSelector);
  const { sensorId } = useAppSelector(anaLyticConfigSelector);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rasterId, setRasterId] = useState('');

  const { clientId } = useParams();

  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());

  const hasPermission = useMemo(() => {
    const currentRole = userInfo?.data?.role?.find((role: RoleType) => role.clientId === clientId);
    return currentRole?.permissions?.some((permission: string) => permission === 'MANAGE_ANALYSIS_EXPORT_SCRIPT_FILE');
  }, [clientId, userInfo]);
  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);

  const handleDelete = useMutation((id: string) => deleteRasterAnalytic(id), {
    onSuccess: () => {
      setOpenDeleteModal(false);
      setRasterId('');
      // refetch();
      toast.success('Delete Raster Analytics Successful', { toastId: 1 });
      dispatch(changePageRasterPagination(1));
      queryClient.invalidateQueries([QUERY_KEY.RASTER_PAGINATION, pageRaster, levelId]);
    },
    onError: () => {
      setOpenDeleteModal(false);
      setRasterId('');
      toast.error('Delete Raster Analytics Error', { toastId: 1 });
    },
  });

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setRasterId('');
  };

  const onSave = () => {
    handleDelete.mutate(rasterId);
  };

  const handleDownloadFile = useMutation(() => getExportAnalyticScript(item.id), {
    onSuccess: (res: any) => {
      const blob = new Blob([res.data.toString()], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, res.headers.filename);
      toast.success(`Download File Successful`, { toastId: 1 });
    },
    onError: () => {
      toast.error(`Download File Error`, { toastId: 2 });
    },
  });

  const handleDownload = () => {
    handleDownloadFile.mutate();
  };

  return (
    <>
      {item?.parentId ? (
        <li
          className={classNames(
            'dnd-sortable-tree_simple_wrapper',
            clone && 'dnd-sortable-tree_simple_clone',
            ghost && 'dnd-sortable-tree_simple_ghost',
            disableSelection && 'dnd-sortable-tree_simple_disable-selection',
            disableInteraction && 'dnd-sortable-tree_simple_disable-interaction'
          )}
          ref={wrapperRef}
          {...rest}
          style={{
            ...style,
            paddingLeft: clone ? indentationWidth : indentationWidth * depth,
          }}>
          <div
            className={'dnd-sortable-tree_simple_tree-item'}
            ref={ref}
            {...(manualDrag ? undefined : handleProps)}
            style={{
              minHeight: '40px',
              border: 0,
              padding: 0,
              marginTop: '10px',
              color: theme.palette.mode === 'dark' ? '#fff' : '#616161',
            }}
          // onClick={disableCollapseOnItemClick ? undefined : onCollapse}
          >
            <Card
              sx={{ maxHeight: '37px' }}
              isSelected={item._id === sensorId}
              isCompulsoryAnalysis={item.isCompulsoryAnalysis}
              onClick={(e) => {
                dispatch(changeSensorSelected({ sensor: item._id, type: item.name }));
                e.stopPropagation();
                e.preventDefault();
              }}>
              <Typography component={'span'}>
                {`${item?.label ? ` ${t(convertAnalyticNameToMultiLanguage(item.label))}` : ''}`}
                {`${item.relabel ? `: ${t(convertTextToMultiLanguage(item.relabel, 'analytic'))}` : ''}`}
              </Typography>
              <Grid sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                {(hasPermission || isSuperAdmin || isSuperUser) &&
                  RasterItemEnable.includes(item.name as TYPE_ANALYTICS_MAP_VIEW) && (
                    <DescriptionIcon onClick={() => handleDownload()} />
                  )}
                <ButtonCustom
                  aria-label="delete"
                  onClick={() => {
                    setRasterId(item._id);
                    setOpenDeleteModal(true);
                  }}>
                  <DeleteIcon fontSize="inherit" />
                </ButtonCustom>
              </Grid>
              {/* delete */}
            </Card>
          </div>
        </li>
      ) : (
        <RasterCard sensor={item} key={item._id} isSelected={sensorId === item._id} />
      )}
      {openDeleteModal && (
        <ClientActionsDialog
          title="Delete Raster"
          open={openDeleteModal}
          onClose={closeDeleteModal}
          description="Are you sure about that?"
          onCancel={closeDeleteModal}
          onSave={onSave}
          submit={false}
        />
      )}
    </>
  );
});

export default RasterCardItem;
