import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { compareKpStrings } from 'common/utils/compare';
import { useBoxLayout, useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { FORMAT_DATE, QUERY_KEY } from 'constants/constants';
import { IPhotoIntelligenceDetailData, IPhotoIntelligenceMLevel, SeverityLevelEnum } from 'constants/photoIntelligence';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import moment, { Moment } from 'moment';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getPhotoIntelligenceAllLocation } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeAssociatedKpInfo,
  changeDetailBarList,
  changeSelectedKpInfo,
  photoIntelligenceSelector,
} from 'store/slices/map-view/photoIntelligenceAnalytics';
import { changeParentAnalyticsId, mapViewSelector } from 'store/slices/mapViewSlice';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import PeriodDateFilter from './component-filter-date/FilterDateYear';
import DatePickerFilter from './component-filter-date/SelectDateRange';

interface ISubCollapseControl {
  key: SeverityLevelEnum;
  isOpen: boolean;
  label: string;
}

export enum Period {
  ONE_MONTH = '1M',
  THREE_MONTHS = '3M',
  SIX_MONTHS = '6M',
  TWELVE_MONTHS = '12M',
  YEAR_TO_DATE = 'YTD',
  LAST_YEAR = 'Last Year',
}

export enum FilterOption {
  DATE = 'date',
  PERIOD = 'period',
}

const PhotoIntelligenceLastLevelSummary = () => {
  const [isShowPhotoIntelligence, setIsShowPhotoIntelligence] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedOption, setSelectedOption] = useState<FilterOption>(FilterOption.DATE);
  const [selectedPeriod, setSelectedPeriod] = useState<string>('');
  const [subCollapseControl, setSubCollapseControl] = useState<ISubCollapseControl[]>([
    { key: SeverityLevelEnum.GROUND_FEATURES, isOpen: false, label: 'trans.ground_features' },
    { key: SeverityLevelEnum.LOW, isOpen: false, label: 'trans.low' },
    { key: SeverityLevelEnum.MEDIUM, isOpen: false, label: 'trans.medium' },
    { key: SeverityLevelEnum.HIGH, isOpen: false, label: 'trans.high' },
  ]);

  const classes = useLayerSelectCardStyle();
  const classes2 = useBoxLayout();
  const { t } = useTranslation();
  const { isRTL } = useCheckRTL();
  const { levelId, isLastLevelId } = useAppSelector(mapViewSelector);
  const { analyticName } = useAppSelector(rightBarSelector);
  const dispatch = useAppDispatch();
  const { barDetailList } = useAppSelector(photoIntelligenceSelector);

  const { data: photoIntelligenceData } = useQuery<IPhotoIntelligenceMLevel[]>(
    [QUERY_KEY.ALL_LOCATION_PHOTO_INTELLIGENCE, levelId],
    () => getPhotoIntelligenceAllLocation(levelId!),
    {
      enabled: !!levelId && isLastLevelId,
    }
  );

  const summaryData = photoIntelligenceData
    ?.map((item) => item.data)
    ?.flat() as unknown as IPhotoIntelligenceDetailData[];

  const sortedData = summaryData?.sort((a, b) => {
    const value = compareKpStrings(a.kpName, b.kpName);
    if (value === 0) return moment(a.analysisId.date).isBefore(b.analysisId.date) ? 1 : -1;
    return value;
  });

  const handleClickSubCollapse = (key: SeverityLevelEnum) => {
    const newSubCollapseControl = subCollapseControl?.map((item) =>
      item.key === key ? { ...item, isOpen: !item.isOpen } : item
    );
    setSubCollapseControl(newSubCollapseControl);
  };

  const waitForKpToLoad = (targetKpId: string): Promise<void> => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const element = document.getElementById(`photo_bar_${targetKpId}`);
        if (element && element.offsetHeight > 100) {
          clearInterval(interval);
          resolve();
        }
      }, 50);
    });
  };

  const onClickTargetKp = async (item: IPhotoIntelligenceDetailData) => {
    const targetKpId = item._id;
    const {
      lon,
      lat,
      analysisId: { date, parentId, _id: analysisId },
    } = item;
    const formattedDate = moment.utc(date).format(FORMAT_DATE);
    dispatch(changeAssociatedKpInfo({ id: targetKpId, date: formattedDate, isDone: false }));
    dispatch(changeParentAnalyticsId({ parentId: parentId, analyticId: analysisId }));

    const newBarDetailList = barDetailList.map((item) =>
      item.id === targetKpId ? { ...item, isOpenCollapse: true } : { ...item, isOpenCollapse: false }
    );
    dispatch(changeDetailBarList(newBarDetailList));
    dispatch(changeSelectedKpInfo({ _id: targetKpId, lon, lat, isDone: false }));
    await waitForKpToLoad(targetKpId);

    const element = document.getElementById(`photo_bar_${targetKpId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  };

  const getPeriodDateRange = (period: Period): Moment | null => {
    const now = moment();
    switch (period) {
      case Period.ONE_MONTH:
        return now.subtract(1, 'month');
      case Period.THREE_MONTHS:
        return now.subtract(3, 'months');
      case Period.SIX_MONTHS:
        return now.subtract(6, 'months');
      case Period.TWELVE_MONTHS:
        return now.subtract(12, 'months');
      case Period.YEAR_TO_DATE:
        return moment().startOf('year');
      case Period.LAST_YEAR:
        return moment().subtract(1, 'year').startOf('year');
      default:
        return null;
    }
  };

  const filteredData = sortedData?.filter((item) => {
    const itemDate = moment(item.analysisId?.date);
    const periodDate = selectedPeriod ? getPeriodDateRange(selectedPeriod as Period) : null;
    const isWithinPeriod = periodDate ? itemDate.isAfter(periodDate) : true;
    return (
      (selectedDate ? itemDate.format('MM-YYYY') === moment(selectedDate).format('MM-YYYY') : true) && isWithinPeriod
    );
  });
  const handleDateChange = (newDate: Date | null) => {
    setSelectedDate(newDate);
    setSelectedPeriod('');
  };

  const handleClearDate = () => {
    setSelectedDate(null);
  };

  const handlePeriodChange = (value: string) => {
    setSelectedPeriod(value);
    setSelectedDate(null);
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value as FilterOption;
    setSelectedOption(selectedValue);
    if (selectedValue === FilterOption.DATE) {
      setSelectedPeriod('');
    } else if (selectedValue === FilterOption.PERIOD) {
      setSelectedDate(null);
    }
  };

  if (!photoIntelligenceData?.length) {
    return null;
  }

  return (
    <Box className={classes2.boxLayout} style={{ padding: '20px 20px', borderRadius: '20px', marginBottom: '20px' }}>
      <Button
        endIcon={
          isShowPhotoIntelligence ? (
            <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
          ) : (
            <ChevronRightIcon style={{ fontWeight: 'bold' }} />
          )
        }
        onClick={() => setIsShowPhotoIntelligence(!isShowPhotoIntelligence)}
        classes={{ root: classes.buttonTextStyle }}
        style={{ width: '100%' }}>
        <Typography
          className={classes.buttonTextStyle}
          style={{ marginRight: 'auto', fontWeight: 'bold', fontSize: '14px !important' }}>
          {t('photo_intelligence.summary_of_photo_intelligence')}
        </Typography>
      </Button>
      <Collapse in={isShowPhotoIntelligence} timeout="auto" unmountOnExit sx={{ px: 1.5 }}>
        <Typography style={{ marginBottom: '5px' }}>{t('trans.filter_by_date')}</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="filter-by-date"
            name="filter-by-date"
            value={selectedOption}
            onChange={handleOptionChange}>
            <FormControlLabel
              value={FilterOption.DATE}
              control={<Radio />}
              label={
                <Box>
                  <DatePickerFilter
                    selectedDate={selectedDate}
                    onDateChange={handleDateChange}
                    onClear={handleClearDate}
                    disabled={selectedOption !== FilterOption.DATE}
                  />
                </Box>
              }
            />
            <FormControlLabel
              value={FilterOption.PERIOD}
              control={<Radio />}
              label={
                <Box>
                  <PeriodDateFilter
                    selectedPeriod={selectedPeriod}
                    onPeriodChange={handlePeriodChange}
                    disabled={selectedOption !== FilterOption.PERIOD}
                  />
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
        {subCollapseControl?.map((item) => (
          <Fragment key={item.key}>
            <Button
              startIcon={
                item.isOpen ? (
                  <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
                ) : (
                  <ChevronRightIcon style={{ fontWeight: 'bold' }} />
                )
              }
              onClick={() => handleClickSubCollapse(item.key)}
              classes={{ root: classes.buttonTextStyle }}
              style={{ width: '100%' }}>
              <Typography
                className={classes.buttonTextStyle}
                style={{
                  marginRight: isRTL ? 0 : 'auto',
                  marginLeft: isRTL ? 'auto' : 0,
                  fontWeight: 'bold',
                  fontSize: '13px !important',
                  textAlign: isRTL ? 'right' : 'left',
                }}>
                {t(item.label)}
              </Typography>
            </Button>
            <Collapse in={item.isOpen} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
              <Grid container>
                {filteredData &&
                  filteredData
                    .filter((ele) => ele.severityLevels.includes(item.key))
                    .map((ele) => (
                      <Grid item xs={6} key={ele._id}>
                        {analyticName ? (
                          <Box
                            component="span"
                            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => onClickTargetKp(ele)}>
                            {ele.kpName}
                          </Box>
                        ) : (
                          <Tooltip title={'Please select analytic to enable this feature'}>
                            <Box component="span" sx={{ textDecoration: 'underline' }}>
                              {ele.kpName}
                            </Box>
                          </Tooltip>
                        )}
                        <Box component="span" sx={{ ml: '4px', opacity: 0.7 }}>
                          {`(${moment(ele.analysisId?.date).format('DD-MM-YYYY')})`}
                        </Box>
                      </Grid>
                    ))}
              </Grid>
            </Collapse>
          </Fragment>
        ))}
      </Collapse>
    </Box>
  );
};

export default PhotoIntelligenceLastLevelSummary;
