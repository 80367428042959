import { Grid, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { useTranslation } from 'react-i18next';

const TreeTiltFallenInfo = ({ hoverInfo }: any) => {
  const { t } = useTranslation();
  const { lengthSetting } = useQueryClientSetting();

  return (
    <Grid item xs={12} direction="row" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography
        component="span"
        sx={{
          fontSize: '14px',
          fontWeight: 'bold',
          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
        }}>
        {t('trans.length')}
      </Typography>
      <Typography
        component="span"
        sx={{
          fontSize: '14px',
          fontWeight: 'normal',
          backgroundColor: (theme) => (theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5'),
          padding: '2px',
          color: '#1C9855',
          width: 'fit-content',
        }}>
        {`${new BigNumber(hoverInfo?.properties?.length).toFixed(lengthSetting.decimalPlace)} m`}
      </Typography>
    </Grid>
  );
};

export default TreeTiltFallenInfo;
