import { IDataAnalysis } from 'common/defines/clients';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { compareKpStrings } from 'common/utils/compare';
import { QUERY_KEY } from 'constants/constants';
import { IPhotoIntelligenceData } from 'constants/photoIntelligence';
import useCheckingEnableAllLevels from 'hooks/common/useCheckingEnableAllLevels';
import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getPhotoIntelligenceAnalysis } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeAssociatedKpInfo,
  changeDetailBarList,
  photoIntelligenceSelector,
} from 'store/slices/map-view/photoIntelligenceAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import CommentViewModal from './modals/comment-view-modal';
import ImageViewModal from './modals/ImageViewModal';
import VideoViewModal from './modals/VideoViewModal';
import PhotoIntelligenceBar from './photo-intelligence-bar';
import PhotoIntelligenceLastLevelSummary from './photo-intelligence-last-level-summary';

interface PhotoIntelligenceAnalyticsProps {
  listDataAnalysis: IDataAnalysis[];
}

const PhotoIntelligenceAnalytics: FC<PhotoIntelligenceAnalyticsProps> = ({ listDataAnalysis }) => {
  const { imageViewModal, videoViewModal, commentViewModal, associatedKp } = useAppSelector(photoIntelligenceSelector);
  const { analyticId, dateAnalyticSelected, ratersSelected } = useAppSelector(mapViewSelector);
  const [listData, setListData] = useState<IPhotoIntelligenceData[]>([]);
  const dispatch = useAppDispatch();
  const { isEnableAnalyticAllLevels } = useCheckingEnableAllLevels();

  useQuery<IPhotoIntelligenceData[]>(
    [QUERY_KEY.PHOTO_INTELLIGENCE_ANALYSIS, analyticId, dateAnalyticSelected, ratersSelected],
    () => getPhotoIntelligenceAnalysis(analyticId!),
    {
      enabled: !!analyticId && isEnableAnalyticAllLevels,
      onSuccess(data) {
        const sortedData = data.sort((a, b) => compareKpStrings(a.kpName, b.kpName));
        setListData(sortedData);
        const detailBarList = sortedData?.map((item) => ({
          id: item._id,
          isOpenCollapse: associatedKp.id === item._id,
        }));
        if (detailBarList?.length) {
          dispatch(changeDetailBarList(detailBarList));
          dispatch(changeAssociatedKpInfo({ id: '', date: '', isDone: true }));
        }
      },
    }
  );

  // reset data when analyticId = null
  useEffect(() => {
    if (!analyticId) {
      setListData([]);
    }
  }, [analyticId]);

  const imageViewModalData = listData?.find((item) => item._id === imageViewModal.id);
  const videoViewModalData = listData?.find((item) => item._id === videoViewModal.id);
  const commentViewModalData = listData?.find((item) => item._id === commentViewModal.id);

  const isShowLastLevelSummary = !!listDataAnalysis.find(
    (item) => item.name === TYPE_ANALYTICS_MAP_VIEW.PHOTO_INTELLIGENCE
  );

  return (
    <>
      {isShowLastLevelSummary && <PhotoIntelligenceLastLevelSummary />}
      {listData?.map((item) => (
        <PhotoIntelligenceBar key={item._id} data={item} />
      ))}
      {imageViewModal.isShow && <ImageViewModal data={imageViewModalData} />}
      {videoViewModal.isShow && <VideoViewModal data={videoViewModalData} />}
      {commentViewModal.isShow && <CommentViewModal data={commentViewModalData} />}
    </>
  );
};

export default PhotoIntelligenceAnalytics;
