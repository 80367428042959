import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { InputAdornment, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import { FORMAT_DATE, QUERY_KEY } from 'constants/constants';
import { isArray, isEmpty } from 'lodash';
import moment from 'moment';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getAllDateLandUseAnalytic } from 'services/MapView/apiMapViewConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeAssociatedKpInfo, photoIntelligenceSelector } from 'store/slices/map-view/photoIntelligenceAnalytics';
import {
  changeAnalyticId,
  changeDateAnalyticSelected,
  changeRastersSelected,
  mapViewSelector,
} from 'store/slices/mapViewSlice';
import { changeAnalyticName } from 'store/slices/rightBarSlice';

interface SelectDateAnalyticsProps {
  levelId: string;
}

export const SelectDateAnalytics: FC<SelectDateAnalyticsProps> = ({ levelId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { dateAnalyticSelected } = useAppSelector(mapViewSelector);
  const { associatedKp } = useAppSelector(photoIntelligenceSelector);

  const { data, isLoading } = useQuery([QUERY_KEY.DATE_HISTORY_LEVEL_M, levelId], () =>
    getAllDateLandUseAnalytic(levelId)
  );

  const datePickers = useMemo(() => {
    if (!data || isEmpty(data.data)) {
      return [];
    }
    const dateList = [...data.data]
      .reverse()
      .map((_item: { date: string; rasters: Array<{ _id: string; name: string }> }, index: number) => ({
        value: index,
        label: moment(_item.date).format('DD-MM-YYYY'),
        apiDate: moment.utc(_item.date).format(FORMAT_DATE),
        rasters: _item.rasters,
      }));
    return dateList;
  }, [data]);

  const toolTipByHover: any = useMemo(() => {
    let hoverData = null;
    if (isArray(datePickers)) {
      hoverData = datePickers.find((item) => item.apiDate === dateAnalyticSelected);
    }
    return hoverData?.rasters[0];
  }, [dateAnalyticSelected, datePickers]);

  const indexValue = useMemo(() => {
    return Math.max(
      0,
      datePickers.findIndex((item) => item.apiDate === dateAnalyticSelected)
    );
  }, [datePickers, dateAnalyticSelected]);

  // auto change date with associate kps in photo intelligence analytic
  useEffect(() => {
    const { date, isDone, id } = associatedKp;
    if (!isDone && date) {
      const foundIndex = datePickers.findIndex((item) => item.apiDate === date);
      dispatch(changeAssociatedKpInfo({ id: id, date: '', isDone: isDone }));
      if (indexValue !== foundIndex) {
        dispatch(changeDateAnalyticSelected(datePickers[foundIndex].apiDate));
        dispatch(changeRastersSelected(datePickers[foundIndex].rasters));
      }
    }
  }, [associatedKp, datePickers, dispatch, indexValue]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isEmpty(datePickers)) {
      dispatch(changeDateAnalyticSelected(null));
      dispatch(changeRastersSelected([]));
    } else if (!dateAnalyticSelected) {
      dispatch(changeDateAnalyticSelected(datePickers[0].apiDate));
      dispatch(changeRastersSelected(datePickers[0].rasters));
    }
  }, [datePickers, dispatch, isLoading, dateAnalyticSelected]);

  const handleChangeDate = (e: SelectChangeEvent<number>) => {
    const value = Number(e.target.value) || 0;
    dispatch(changeDateAnalyticSelected(datePickers[value].apiDate));
    dispatch(changeRastersSelected(datePickers[value].rasters));
    dispatch(changeAnalyticName(''));
    dispatch(changeAnalyticId(''));
  };

  if (datePickers.length === 0) return null;

  return (
    <Tooltip title={toolTipByHover?.description || t('trans.no_description')} placement="top-end">
      <Select
        id="select-date-analytics"
        value={indexValue}
        onChange={(e) => handleChangeDate(e)}
        size="small"
        sx={{ width: '160px' }}
        startAdornment={
          <InputAdornment position="start">
            <CalendarMonthIcon />
          </InputAdornment>
        }>
        {datePickers.map((item, index) => (
          <MenuItem value={index} key={index}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  );
};
